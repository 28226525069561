<template>
  <div class="site-wrapper site-page--login">
    <div class="site-content__wrapper">
      <div class="site-content">
        <!-- <div class="brand-info">
          <h2 class="brand-info__text">家庭医生签约服务——政府监管平台</h2>
          <p class="brand-info__intro">
            心狗健康旨在做心脏健康最忠诚的守护者，研发出了具有科技前瞻性的心狗便携式智能健康系列产品，涵盖智能动静态心电监测、血压血氧监测、以及女性生理监测。
          </p>
        </div> -->
        <component v-bind:is='currentLogin'></component>
        <el-button
          v-if="fromMainHome"
            class="back-btn"
            type="primary"
            @click="$router.replace({name:'index'})"
            >退回首页</el-button
            >
      </div>
    </div>
    <main-footer />
  </div>
</template>

<script>
import '../../assets/theme/el-input/theme/index.css'
import exampleLogin from '../example_modules/login.vue'
import example1Login from '../example_module1/login.vue'
import stationLogin from '../health_station/login.vue'
import my_healthLogin from '../personal_module/login.vue'
import communityLogin from '../community_station/login.vue'
import doctorLogin from '../doctor_station/login.vue'
import WomenBabyLogin from '../women_baby/login.vue'
import OlderStationLogin from '../older_station/login.vue'
import HomeOlderLogin from '../home_older/login.vue'
import MainFooter from '../main-footer'
export default {
  data () {
    return {
      fromMainHome: this.$store.state.module.fromMainHome
    }
  },
  components:{
    MainFooter,
    exampleLogin,
    example1Login,
    stationLogin,
    my_healthLogin,
    communityLogin,
    doctorLogin,
    WomenBabyLogin,
    OlderStationLogin,
    HomeOlderLogin
    
  },
  computed: {
    // fromMainHome() {
    //   return this.$store.state.module.fromMainHome;
    // },
    crossPlatformStatus: { // 这里是考虑 从[医生平台]跳转到[个人平台]时，crossPlatformStatus需要恢复为false
            get() { return this.$store.state.module.crossPlatform },
            set(val) { this.$store.commit('module/updateCrossPlatform', val) }
        },
    currentLogin: function () {
      console.log(this.$store.state.module.moduleName)
      if (this.$store.state.module.moduleName === 'example_module') {
        return 'exampleLogin'
      }
      else if (this.$store.state.module.moduleName === 'example_module1') {
        return 'example1Login'
      }
      else if (this.$store.state.module.moduleName === 'health_station') {
        return 'stationLogin'
      }
      else if (this.$store.state.module.moduleName === 'personal_module') {
        return 'my_healthLogin'
      }
      else if (this.$store.state.module.moduleName === 'community_station') {
        return 'communityLogin'
      }
      else if (this.$store.state.module.moduleName === 'doctor_station') {
        return 'doctorLogin'
      }
      else if (this.$store.state.module.moduleName === 'women_baby'){
        return 'WomenBabyLogin'
      }
      else if (this.$store.state.module.moduleName === 'older_station'){
        return 'OlderStationLogin'
      }
      else if (this.$store.state.module.moduleName === 'home_older'){
        return 'HomeOlderLogin'
      }
      else {
        return 'exampleLogin'
      }
    },
  },
  created () {
    this.crossPlatformStatus = false // 这里是考虑 从[医生平台]跳转到[个人平台]时，crossPlatformStatus需要恢复为false
    this.fromMainHome = this.$store.state.module.fromMainHome

  },
  methods: {
  }
}
</script>

<style lang="scss">
.site-wrapper.site-page--login {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // background-color: rgba(176, 245, 170, 0.6);
  overflow: hidden;
  &:before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url(~@/assets/img/login2.jpg);
    // background-blend-mode: lighten;
    background-size: cover;
  }
  .site-content__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: transparent;
  }
  .site-content {
    min-height: 100%;
    padding: 30px 500px 30px 30px;
  }
  .back-btn {
    border-color: #428bca;
    background-color: #428bca;
  }
}
</style>
